function SlotGameList() {
    return (
      <>
<div className="games-container">
  <div id="all" className="slotsContent">
    <div id="gamelist" className="hidden-xs">Loading Gamelist...</div>
    <div id="mobile-gamelist" className="visible-xs">Loading Mobile Gamelist...</div>
  </div>
  <div id="featured" className="slotsContent">
    <div id="featured-gamelist" className="hidden-xs">Loading Gamelist...</div>
    <div id="mobile-featured-gamelist" className="visible-xs">Loading Mobile Gamelist...</div>
  </div>
  <div id="jpslots" className="slotsContent">
    <div id="jpslots-gamelist" className="hidden-xs">Loading Gamelist...</div>
    <div id="mobile-jpslots-gamelist" className="visible-xs">Loading Mobile Gamelist...</div>
  </div>
  <div id="slots" className="slotsContent">
    <div id="slots-gamelist" className="hidden-xs">Loading Gamelist...</div>
    <div id="mobile-slots-gamelist" className="visible-xs">Loading Mobile Gamelist...</div>
  </div>
  <div id="table" className="slotsContent">
    <div id="table-gamelist" className="hidden-xs">Loading Gamelist...</div>
    <div id="mobile-table-gamelist" className="visible-xs">Loading Mobile Gamelist...</div>
  </div>
  <div id="videopoker" className="slotsContent">
    <div id="videopoker-gamelist" className="hidden-xs">Loading Gamelist...</div>
    <div id="mobile-videopoker-gamelist" className="visible-xs">Loading Mobile Gamelist...</div>
  </div>
  <div id="arcade" className="slotsContent">
    <div id="arcade-gamelist" className="hidden-xs">Loading Gamelist...</div>
    <div id="mobile-arcade-gamelist" className="visible-xs">Loading Mobile Gamelist...</div>
  </div>
</div>
      </>
    )
  }
export default SlotGameList