import "../style/accountStyle.css";
import AccountSelection from "../snippets/AccountSelection"
function GameReport() {

    return (
      <>
      <div className="account-content report-page">
        <AccountSelection />
        <div className="welcome-user">
          <span className="welcome">Welcome, </span><span className="user-name">...</span><button id="verifyAccountPUM" className="popmake-4154 pum-trigger">Verify</button><button id="uploadPhotoPUM" className="popmake-4137 pum-trigger">Upload Photo</button>
        </div>
        <div className="account-container tier-info">
            <div className="report-info">
              <h2>Gaming Report</h2>
              <div className="title-line"></div>
              <p className="referral-text">View your gaming report within the selected date range. Report reflects data recorded from 6AM (GMT+8) on the selected date to 6AM (GMT+8) the following day.</p>
            </div>
            <div className="table-fields">
              <form id="gr-requestForm">
                <div className="from-to-type-container">
                  <div className="field-row">
                    <label>From:</label>
                    <input type="date" name="fd" />
                  </div>
                  <div className="field-row">
                    <label>To:</label>
                    <input type="date" name="td" />
                  </div>
                  <div className="field-row">
                    <button id="gr-searchBtn" className="yellow-btn" type="button"><i className="fa fa-search"></i>SEARCH</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="display-flex" style={{marginTop: "40px"}}>
              <div className="summary-amount">
                <div className="amount" id="gr-turnover">--</div>
                <div className="amount-label">Turnover</div>
              </div>
              <div className="summary-amount">
                <div className="amount" id="gr-winloss">--</div>
                <div className="amount-label">Winloss</div>
              </div>
              <div className="summary-amount" id="gr-details">
                <div className="report-detail">Details</div>
              </div>
            </div>
            <div className="summary" id="gr-summary" style={{marginTop: "15px"}}>
              <div>
                <table>
                  <tr>
                    <th>Provider</th>
                    <th>Turnover</th>
                    <th>Winloss</th>
                  </tr>
                  <tr id="gr-reportList"></tr>
                </table>
              </div>
            </div>
          </div>
      </div>
      <div className="main-content-wrapper">
        <div className="text-content-area">
            <h2 className="text-content-title">Who is SG8 Casino?</h2>
            <p>Experience the thrill of online gaming at SG8 Casino Philippines, your ultimate destination for a wide range of games. As a PAGCOR-licensed casino, we offer a safe and exciting environment for players, featuring everything from engaging Online Slots and Live Casino experiences to thrilling Sports Betting. Join us for unparalleled entertainment and big wins!</p>
            <span className="text-content-sub-title">How to Register at SG8 Casino?</span>
            <p>Experience the thrill of online gaming at SG8 Casino Philippines, your ultimate destination for a wide range of games. As a PAGCOR-licensed casino, we offer a safe and exciting environment for players, featuring everything from engaging Online Slots and Live Casino experiences to thrilling Sports Betting. Join us for unparalleled entertainment and big wins!</p>
            <span className="text-content-sub-title">How to Register at SG8 Casino?</span>
            <p>Experience the thrill of online gaming at SG8 Casino Philippines, your ultimate destination for a wide range of games. As a PAGCOR-licensed casino, we offer a safe and exciting environment for players, featuring everything from engaging Online Slots and Live Casino experiences to thrilling Sports Betting. Join us for unparalleled entertainment and big wins!</p>
            <div></div>
        </div>
      </div>
      </>
)
}

export default GameReport