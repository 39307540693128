import React, { useState, useEffect } from 'react';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    const savedUsername = localStorage.getItem('username');
    if (loggedInStatus === 'true' && savedUsername) {
      setIsLoggedIn(true);
      setUsername(savedUsername); // Set username from localStorage
    }

    // Update body class based on login state
    document.body.classList.toggle('isLoggedIn', isLoggedIn);

    // Populate elements with class "userID" with the username
    const userIDElements = document.getElementsByClassName('userID');
    Array.from(userIDElements).forEach(element => {
      element.textContent = savedUsername;
    });
  }, [isLoggedIn]);

  const handleLogin = async () => {
    try {
      const response = await fetch("https://sg8.casino/json/wlp/sg8/json.jsp", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams({
          cmd: "loginjs",
          u: username,
          p: password
        })
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      if (data.s === "success") {
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('username', username);
        setIsLoggedIn(true);
        window.location.href = "/";
      } else {
        setErrorMessage(data.emsg || "Login failed. Please try again.");
        console.error("Login failed:", data);
      }
    } catch (error) {
      setErrorMessage("There was a problem with the login operation. Please try again.");
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <div>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {isLoggedIn ? (
        <>
        </>
      ) : (
        <>
          <input type="text" onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
          <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
          <button onClick={handleLogin}>Login</button>
        </>
      )}
    </div>
  );
}

export default Login;
