import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SlickHomebanner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className='sg8-hero-banner'>
      <Slider {...settings}>
        <div>
          <img src="/images/live-casino.webp" alt="Live Casino" />
        </div>
        <div>
          <img src="/images/slot.webp" alt="Slot" />
        </div>
        <div>
          <img src="/images/sportsbook.webp" alt="Sportsbook" />
        </div>
      </Slider>
    </div>
  );
};

export default SlickHomebanner;