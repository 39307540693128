function Footer() {
  return (
    <>
        <div id="content" className="site-content">
            <footer>
                <div className="sg8-footer-main">
                    <div className="footer-c1">
                        <div className="footer-flexbox">
                            <p className="footer-headline-text">Resposible Gaming</p>
                            <p className="footer-text">This website offers gaming with risk experience. To be a user of our site you must be over 21 years old. We are not responsible for the violation of the local laws.</p>
                        </div>
                        <div className="footer-flexbox">
                            <p className="footer-headline-text">SG8 Casino Filipino</p>
                            <div className="quick-links-container">
                                <ul className="footer-links">
                                    <a href="/live-casino/"><li>About Us</li></a>
                                    <a href="/live-casino/"><li>Blog</li></a>
                                    <a href="/live-casino/"><li>Responsible Gaming</li></a>
                                    <a href="/live-casino/"><li>Self-Exclusion</li></a>
                                    <a href="/live-casino/"><li>FAQ</li></a>
                                    <a href="/live-casino/"><li>Privacy Policy</li></a>
                                    <a href="/live-casino/"><li>Terms & Conditions</li></a>
                                </ul>
                                <ul className="footer-links">
                                    <a href="/live-casino/"><li>Slot Games</li></a>
                                    <a href="/live-casino/"><li>Live Casino</li></a>
                                    <a href="/live-casino/"><li>Sportsbook</li></a>
                                    <a href="/live-casino/"><li>Promotions</li></a>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-flexbox">
                            <p className="footer-headline-text">Resposible Gaming</p>
                        <div className="contact-section">
                            <a href="tel:+639186531060"><img src="/images/phone.webp" title="Connect with us" alt="Reach out via phone or email on sg8.casino!"/></a>
                            <a href="https://telegram.me/SG8_Casino" target="_new" rel="noopener"><img src="/images/telegram.webp" title="Connect with us" alt="Reach out via telegram on sg8.casino!"/></a>
                            <a href="https://wa.me/639186531060" target="_new" rel="noopener"><img src="/images/whatsapp.webp" title="Connect with us" alt="Reach out via whatsapp on sg8.casino!"/></a>
                            <a href="viber://chat?number=639186531060" target="_new" rel="noopener"><img src="/images/viber.webp" title="Connect with us" alt="Reach out via viber on sg8.casino!"/></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="sg8-footer-bottom">
                    <div className="tagline-footer">
                        <p>© 2023 SG8.Casino. All rights reserved.  SG8 Casino licensed and regulated by (PAGCOR)</p>
                    </div>
                </div>
            </footer>
        </div>
    </>
  )
}

export default Footer