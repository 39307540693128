import React from "react";
import "../style/loading.css"
export default function LoadingSpinner() {
  return(
    <>
    <section className="loader">
        <div>
            <div className="circle"></div>
            <div className="text">Please wait...</div>
        </div>
    </section>
  </>
  ) 
}
