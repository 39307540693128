import { Helmet } from "react-helmet";

function Sports() {
    return (
      <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Pinnacle Sportsbook | Bet on your favorite teams now! - SG8 Casino</title>
            <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        </Helmet>
        <div className="main-wrapper desktop-view">
          <div className="main-content-area">
              <div className="page-headlines-container">
                  <div className="page-h2-text-style">
                    <h2 id="pinnaclesports">Pinnacle Sports</h2> <span>Bet on your favorite team now!</span>
                  </div>
                </div>
                  <div className="sportsbook-game-selector">
                      <div className="sports-desktop-selector">
                        <div className="window-selector">
                          <div className="selection pinnaclesports"><button onClick="launchGame('https://hszbtad.tender88.com/en/sports','62001',false)">open in new window <img style={{maxWidth: "18px", opacity: ".7", verticalAlign: "middle"}} src="/images/new-window-icon.png"/></button></div>
                        </div>
                      </div>
                      <iframe className="gameIframe" src=""></iframe>
                  </div>
          </div>
        </div>
          
        <div className="main-content-wrapper">
          <div className="text-content-area">
              <h2 className="text-content-title">Who is SG8 Casino?</h2>
              <p>Experience the thrill of online gaming at SG8 Casino Philippines, your ultimate destination for a wide range of games. As a PAGCOR-licensed casino, we offer a safe and exciting environment for players, featuring everything from engaging Online Slots and Live Casino experiences to thrilling Sports Betting. Join us for unparalleled entertainment and big wins!</p>
              <span className="text-content-sub-title">How to Register at SG8 Casino?</span>
              <p>Experience the thrill of online gaming at SG8 Casino Philippines, your ultimate destination for a wide range of games. As a PAGCOR-licensed casino, we offer a safe and exciting environment for players, featuring everything from engaging Online Slots and Live Casino experiences to thrilling Sports Betting. Join us for unparalleled entertainment and big wins!</p>
              <span className="text-content-sub-title">How to Register at SG8 Casino?</span>
              <p>Experience the thrill of online gaming at SG8 Casino Philippines, your ultimate destination for a wide range of games. As a PAGCOR-licensed casino, we offer a safe and exciting environment for players, featuring everything from engaging Online Slots and Live Casino experiences to thrilling Sports Betting. Join us for unparalleled entertainment and big wins!</p>
              <div></div>
          </div>
        </div>
      </>
    )
  }
  
export default Sports