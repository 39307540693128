function SlotCatList() {
    return (
      <>
<div className="categories">
<div>
    <div className="slotsCategory all active" onclick="openCategory('all')">
    <img src="/images/all-games.png" alt="all casino filipino games"/>
    <div className="cat-title">All</div>
    </div>
    <div className="slotsCategory featured" onclick="openCategory('featured')">
    <img src="/images/featured.png" alt="all featured casino filipino games"/>
    <div className="cat-title">Featured</div>
    </div>
    <div className="slotsCategory jpslots" onclick="openCategory('jpslots')">
    <img src="/images/jackpot-slots.png" alt="all jackpot casino filipino games"/>
    <div className="cat-title">Jackpot Slots</div>
    </div>
    <div className="slotsCategory slots" onclick="openCategory('slots')">
    <img src="/images/slots-3.png" alt="all casino filipino slots games"/>
    <div className="cat-title">Slots</div>
    </div>
    <div className="slotsCategory table" onclick="openCategory('table')">
    <img src="/images/table-games.png" alt="all table games for casino filipino"/>
    <div className="cat-title">Table Games</div>
    </div>
    <div className="slotsCategory videopoker" onclick="openCategory('videopoker')">
    <img src="/images/poker-1.png" alt="all poker games for casino filipino"/>
    <div className="cat-title">Video Poker</div>
    </div>
    <div className="slotsCategory arcade" onclick="openCategory('arcade')">
    <img src="/images/arcade.png" alt="all casino filipino arcade games"/>
    <div className="cat-title">Arcade</div>
    </div>
    <div className="slotsCategory fishing" onclick="openCategory('fishing')">
    <img src="/images/fishing-2.png" alt="all fishing games for casino filipino"/>
    <div className="cat-title">Fishing</div>
    </div>
</div>
</div>
      </>
    )
  }
export default SlotCatList