import ProviderSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SlotProviderList  = () => {
  const settings = {
    infinite: true,
    slidesToShow: 8,
    slidesToScroll: 1
  };
    return (
      <>
<div className="slot-provider-container">
  <ProviderSlider {...settings}>
  <div className="provider-item">
    <a href="/slots/rtg/" className="rtg">
      <img decoding="async" src="/images/provider-rtg-logo.webp" alt="rtg slots philippines"/>
    </a>
  </div>
  <div className="provider-item">
    <a href="/slots/rtg/" className="rtg">
      <img decoding="async" src="/images/provider-rtg-logo.webp" alt="rtg slots philippines"/>
    </a>
  </div>
  <div className="provider-item">
    <a href="/slots/rtg/" className="rtg">
      <img decoding="async" src="/images/provider-rtg-logo.webp" alt="rtg slots philippines"/>
    </a>
  </div>
  <div className="provider-item">
    <a href="/slots/rtg/" className="rtg">
      <img decoding="async" src="/images/provider-rtg-logo.webp" alt="rtg slots philippines"/>
    </a>
  </div>
  <div className="provider-item">
    <a href="/slots/rtg/" className="rtg">
      <img decoding="async" src="/images/provider-rtg-logo.webp" alt="rtg slots philippines"/>
    </a>
  </div>
  <div className="provider-item">
    <a href="/slots/rtg/" className="rtg">
      <img decoding="async" src="/images/provider-rtg-logo.webp" alt="rtg slots philippines"/>
    </a>
  </div>
  <div className="provider-item">
    <a href="/slots/rtg/" className="rtg">
      <img decoding="async" src="/images/provider-rtg-logo.webp" alt="rtg slots philippines"/>
    </a>
  </div>
  <div className="provider-item">
    <a href="/slots/rtg/" className="rtg">
      <img decoding="async" src="/images/provider-rtg-logo.webp" alt="rtg slots philippines"/>
    </a>
  </div>
  <div className="provider-item">
    <a href="/slots/rtg/" className="rtg">
      <img decoding="async" src="/images/provider-rtg-logo.webp" alt="rtg slots philippines"/>
    </a>
  </div>
  </ProviderSlider>
</div>
      </>
    )
  }
export default SlotProviderList