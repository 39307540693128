function BaDepositDropdown() {

    return (
      <>
		<div className="row">
			<div className="hLabel">Brand Ambassador</div>
			<div className="input-container">
				<select name="ba" className="form-control">
					<option value="">N/A</option>
					<option value="1">Brand Ambassador 1</option>
					<option value="2">Brand Ambassador 2</option>
				</select>
			</div>
		</div>
      </>
    )
  }
  
  export default BaDepositDropdown
