import { Helmet } from "react-helmet";

function LiveCasino() {
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Evolution Games | Ultimate Live Casino - SG8 Casino </title>
          <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
      </Helmet>
      <div className="main-wrapper desktop-view">
        <div className="main-content-area">
            <div className="featured-slots-container">
              <div className="page-headlines-container">
                <div className="page-h2-text-style">
                  <h2>Ultimate Live Casino</h2> <span>Evolution Games - SA Gaming - AFB Gaming</span>
                </div>
              </div>
                <div className="ld-game-selector">
                    <div className="ld-game-box"><img src="/images/evolution-games.webp" alt=""/><button className="yellow-btn">Play Now</button></div>
                    <div className="ld-game-box"><img src="/images/sa-gaming.webp" alt=""/><button className="yellow-btn">Play Now</button></div>
                    <div className="ld-game-box"><img src="/images/afb-gaming.webp" alt=""/><button className="yellow-btn">Play Now</button></div>
                </div>
            </div>
        </div>
      </div>

      <div className="main-content-wrapper">
        <div className="text-content-area">
            <h2 className="text-content-title">Who is SG8 Casino?</h2>
            <p>Experience the thrill of online gaming at SG8 Casino Philippines, your ultimate destination for a wide range of games. As a PAGCOR-licensed casino, we offer a safe and exciting environment for players, featuring everything from engaging Online Slots and Live Casino experiences to thrilling Sports Betting. Join us for unparalleled entertainment and big wins!</p>
            <span className="text-content-sub-title">How to Register at SG8 Casino?</span>
            <p>Experience the thrill of online gaming at SG8 Casino Philippines, your ultimate destination for a wide range of games. As a PAGCOR-licensed casino, we offer a safe and exciting environment for players, featuring everything from engaging Online Slots and Live Casino experiences to thrilling Sports Betting. Join us for unparalleled entertainment and big wins!</p>
            <span className="text-content-sub-title">How to Register at SG8 Casino?</span>
            <p>Experience the thrill of online gaming at SG8 Casino Philippines, your ultimate destination for a wide range of games. As a PAGCOR-licensed casino, we offer a safe and exciting environment for players, featuring everything from engaging Online Slots and Live Casino experiences to thrilling Sports Betting. Join us for unparalleled entertainment and big wins!</p>
        </div>
      </div>
    </>
  )
}

export default LiveCasino